.search-form {
	margin: 5rem 0;
	label {
		@include uppercase;
		color: $primary-dark;
		letter-spacing: 3px;
		font-size: 0.85rem;
		margin-bottom: 0.5rem;
		font-weight: 600;
		&.light {
			font-weight: 400;
		}
	}
	.form-control, .form-select {
		border-radius: 3px;
		border: 0;
		background-color: $primary-light;
		color: lighten($text-lighter, 30%) !important;
		font-size: 0.8rem;
		line-height: 2rem;
		margin-bottom: 2rem;
		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}
		&:disabled {
			background-color: lighten($primary-light, 2%);
			border: 1px solid $white;
		}
	}	
	&.fixed-sort {
		position: fixed;
		left: 0;
		bottom: 0;
		transform: 0;
		text-align: center;
		z-index: 100;
	    background: $white;
	    padding: 1rem;
		box-shadow: 0 0 30px rgb(0 0 0 / 65%);
		@include media-breakpoint-down(lg) {
		right: 0;
		}
		@include media-breakpoint-up(lg) {
		    left: 0;
		    right: 0;
		    width: 300px;
		    margin: auto;
		    bottom: 0%;
		    border-radius: 0 0 8px 8px;
		}
	    .fixed-sort-label {
	    	font-size: 0.75rem;
	    	padding-right: 0.65rem;
	    }
	}
}