.general-introduction {
	padding: 6rem 0;
	@include media-breakpoint-down(md) {
		padding: 4rem 0;
	}
	h2 {
		@include font-primary-semi-bold;
	}
	h5 {
		@include uppercase;
		color: $primary-dark;
		font-weight: 600;
		letter-spacing: 4px;
	}
}