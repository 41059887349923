.lightbox-message {
	background-color: $primary-light;
	padding-bottom: 8rem;
	.lightbox-message-container {
		background-color: $blue;
	    border-radius: 5px;
	    border: 1px solid;
		padding: 2rem 3rem;
	    text-align: center;
		p {
			color: $white;
		}
	}
}
.lightbox-section {
	background-color: $primary-light;
}
.lightbox-detail-buttons {
	.nav-tabs {
		border-bottom: 0;
		.btn {
		    border-bottom: 0;
		    margin-bottom: 0px;
			&.active {
				background-color: $primary-light;
				color: $primary-dark;
				&:after {
					background-image: url('/images/arrow-right.svg') !important;
				}
			}
		}
	}
}
.lightbox-tab-content {
	background-color: $primary-light;
	.tab-content {
		padding: 3rem 0;
		form {
			input:-webkit-autofill,
			input:-webkit-autofill:hover, 
			input:-webkit-autofill:focus, 
			input:-webkit-autofill:active
			{
			 -webkit-box-shadow: 0 0 0 30px $white inset !important;
			}
			label {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;
			}
			.form-control, .form-select {
				border-radius: 3px;
				border: 0;
				color: $text;
				font-size: 0.8rem;
				line-height: 2rem;
				&:disabled {
					background-color: lighten($primary-light, 2%);
					border: 1px solid $white;
				}
			}
			small {
				color: lighten($primary-dark, 10%);
				font-size: 0.7rem;
			}
		}
	}
}
.lightbox-card-models {
	.card {
		.lightbox-card-img {
			height: 250px;
			object-fit: cover;
			@include media-breakpoint-up(sm) {
				height: 400px;
			}
		}
		.model-details {
			bottom: 0;
		}
	}
}

.lightbox-card-footer {
	padding: 1rem 0;
	text-align: center;
	.model-remove {
		background-color: $black;
		color: $white;
		width: 100%;
		display: block;
		padding: 0.5rem 0;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.75rem;
	}
 	.model-name {

	}
}