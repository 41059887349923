.text-banner {
	background-color: $primary-light;
	padding: 3rem;
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
	}
}
.model-inner-text-banner {
	background-color: $primary-light;
 	padding: 3rem 3rem 0 3rem;
 	@include media-breakpoint-down(md) {
 		padding: 2rem 2rem 0 2rem;
 	}
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
		font-weight: 600;
	}
}
.apply-text-banner {
	background-color: $primary-light;
 	padding: 3rem 3rem 0 3rem;
 	@include media-breakpoint-down(md) {
 		padding: 2rem 2rem 0 2rem;
 	}
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
		font-weight: 500;
	}
}
.apply-review-banner {
	background-color: $primary-light;
 	padding: 3rem 3rem 5rem 3rem;
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
		font-weight: 500;
	}
}

.login-banner {
	background-color: $primary-light;
 	padding: 3rem 3rem 5rem 3rem;
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
		font-weight: 500;
	}
}
.image-text-banner {
	background-color: rgba(193, 193, 193, 0.5);
    bottom: 0;
    left: 0;
	padding: 3rem;
	position: absolute;
    right: 0;
    @include media-breakpoint-down(md) {
    	padding: 1rem 3rem;
    }
	.banner-caption {
		font-size: calc( #{$font-size-base} * 1.15);
	}
	h1 {
		color: $white;
	}
}
.error-banner {
    align-items: center;
	background-color: $primary-light;
 	display: flex;
 	min-height: 80vh;
 	padding: 3rem 3rem 0 3rem;
 	@include media-breakpoint-down(md) {
 		padding: 2rem 2rem 0 2rem;
 	}
	.banner-caption {
		color: $primary-dark;
		font-size: calc( #{$font-size-base} * 1.15);
		font-weight: 500;
	}
}