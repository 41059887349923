// Navigation List Items
.menu-trigger-wrapper, .menu-trigger-wrapper-close {
	&:hover {
		cursor: pointer;
		.menu-bar-1 {
			transform: translateY(-3px);
		}
    	.menu-bar-2 {
    		transform: translateY(3px);
		}
	}
	.menu-title {
		@include font-primary-light;
		@include uppercase;
		color: $black;
		font-size: 0.8rem;
		letter-spacing: 2px;
	}
	.menu-bar-wrapper {
		.menu-bar {
		    @include transition;
		    background-color: $black;
		    height: 2px;
		    display: block;
		    margin-bottom: 8px;
		    margin-left: 1rem;
		    position: relative;
		    width: 40px;
		    &:last-of-type {
		    	margin-bottom: 0px;
		    }
		}
	}
}
.menu-trigger-wrapper.white, .menu-trigger-wrapper-close.white {
	.menu-title {
		color: $white;
	}
	.menu-bar-wrapper {
		.menu-bar {
		    background-color: $white;
		}
	}
}
.menu-trigger-wrapper-close {
	&:hover {
		.menu-bar-wrapper {
			.menu-bar-1 {
			transform: translateY(5px) rotate(35deg);
			}
	    	.menu-bar-2 {
	    		transform: translateY(-5px) rotate(-35deg);
			}
		}
	}
	.menu-title {
		color: $white;
	}
	.menu-bar-wrapper {
		.menu-bar {
			background-color: $white;
			width: 30px;
		}
		.menu-bar-1 {
			@include transition;
			transform: translateY(5px) rotate(25deg);
		}
    	.menu-bar-2 {
    		@include transition;
    		transform: translateY(-5px) rotate(-25deg);
		}
	}
}
.navbar-alternative {
	.menu-title {
		color: $white;
	}
	.menu-bar {
		background-color: $white !important;
	}
	@include media-breakpoint-down(md) {
		.menu-title {
			color: $primary-dark;
		}
		.menu-bar {
			background-color: $primary-dark !important;
		}
	}
}