.login-register-block {
	background-color: $primary-light;
	margin-top: -5rem;
	position: relative;
	&:after {
		@include transition;
		background: linear-gradient(90deg, $text-lighter 0%, $text 100%);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
	    top: 50%;
	    z-index: 0;
	}
	.z-index-fix {
		position: relative;
		z-index: 3;
	}
	.card {
		padding: 3rem;
		form {
			label {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;
			}
			.form-detail {
				font-size: 0.65rem;
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
 				margin-bottom: 0rem;
			}
			.form-gdpr {
				text-transform: none;
				letter-spacing: 0;
				font-size: 0.65rem;
				color: $primary-dark;
 				margin-bottom: 0rem;
			}
			.form-control, .form-select {
				background-color: $primary-light;
				border-radius: 3px;
				border: 0;
				color: $text;
				font-size: 0.8rem;
				line-height: 2rem;
				&:disabled {
					background-color: lighten($primary-light, 2%);
					border: 1px solid $white;
				}
			}
		}
	}
	.show-password-wrap {
		position: relative;
		display: flex;
	    align-items: center;
	    justify-content: flex-end;
		.show-password {
			position: absolute;
			top: 4px;
			right: 5px;
		}
	}
}