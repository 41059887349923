.model-info-bar-wrapper {
	margin: 1.5rem 0;
	.model-info-bar {
		background-color: $primary-light;
		border-radius: 4px;
		color: $primary-dark;
		.bold {
			font-weight: 600;
		}
	}
}
