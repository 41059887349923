.burger-menu-sub-nav {
	background-color: $black;
	position: relative;
	top: -16px;
	z-index: 10;
	@include media-breakpoint-down(md) {
		bottom: 0;
		height: calc(25vh - 48px);
		width: 100%;
		top: 0;
	}
	.burger-menu-sub-nav-list {
		display: inline-flex;
		list-style-type: none;
		padding: 0;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			display: inline-block;
			width: 100%;
		}
		.burger-menu-sub-nav-item {
			@include font-primary-light;
			@include uppercase;
			font-size: 0.8rem;
			letter-spacing: 3px;
			padding-left: 2rem;
			margin-bottom: 0;
			text-align: right;
			@include media-breakpoint-down(md) {
				font-size: 0.6rem;
				padding-top: 0.25rem;
				text-align: center;
			}
		}
		.burger-menu-sub-nav-search {
			background-color: $primary;
			margin-left: 1rem;
			padding: 1rem 2rem;
			a {
				@include font-primary-light;
				@include uppercase;
				color: $text;
				font-size: 0.8rem;
				letter-spacing: 3px;
				margin-bottom: 0;
				text-align: right;
			}
		}
	}
}