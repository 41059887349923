.splash-modal {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
	height: 100vh;
	margin-top: 0;
	background: linear-gradient(45deg, $primary 0%, $white 100%);
	z-index: 10000;
    position: absolute;
}