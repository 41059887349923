.h1-homepage {
	@include uppercase;
	letter-spacing: 5px;
}
.h1-lg {
	@include font-primary-semi-bold;
	font-size: calc( #{$font-size-base} * 2.25);
	@include media-breakpoint-down(md) {
		font-size: calc( #{$font-size-base} * 1.5);
	}
}
.h1-sm {
	@include font-primary-semi-bold;
	font-size: calc( #{$font-size-base} * 1.5);
	@include media-breakpoint-down(md) {
		font-size: calc( #{$font-size-base} * 0.9);
	}
}
.h1-text {
	@include font-primary-semi-bold;
	@include uppercase;
	font-size: calc( #{$font-size-base} * 4);
	color: $primary-dark;
	letter-spacing: calc( #{$font-size-base} / 2);
	padding: 2rem 0;
	@include media-breakpoint-down(md) {
		font-size: calc( #{$font-size-base} * 3);
	}
	@include media-breakpoint-down(sm) {
		font-size: calc( #{$font-size-base} * 1.35);
		letter-spacing: calc( #{$font-size-base} / 4);
		line-break: anywhere;
	}
}