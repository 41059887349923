// Grid Layout And Container variables
$grid-breakpoints: (
	xs: 0,
 	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px,
	xxl: 1450px

	// If you need an extra wide container, add the below varible
	// xxxl: 1600px

);
	
$container-max-widths: (
 	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
	xxl: 1500px

	// If you need an extra wide container, add the below varible
	// xxxl: 1640px
	
);