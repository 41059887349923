.apply-journey {
	background: $primary-light;
	position: relative;
	margin-top: 10rem;
	h2 {
		color: $text;
		letter-spacing: 5px;
		font-size: calc( #{$font-size-base} * 2.5);
	}
	h3 {
		@include uppercase;
		color: $primary-dark;
		letter-spacing: 4px;
		font-weight: 600;
		font-size: calc( #{$font-size-base} * 1.25);
	}
	&.apply-journey-alt {
		@include transition;	 
		background: $primary-light;
	}
	.apply-section {
		margin-bottom: 3rem;
		label {
			@include uppercase;
			color: $primary-dark;
			letter-spacing: 3px;
			font-size: 0.85rem;
			margin-bottom: 0.5rem;
		}
		.form-control, .form-select {
			border-radius: 3px;
			border: 0;
			color: $text;
			font-size: 0.8rem;
			line-height: 2rem;
			margin-bottom: 1rem;
			&:disabled {
				background-color: lighten($primary-light, 2%);
				border: 1px solid $white;
			}
 		}
		.details-validation {
			@include uppercase;
			background-color: $white;
			color: $primary-dark;
			border-radius: 4px;
			letter-spacing: 4px;
			padding: 1rem 2rem;
 			font-weight: 700;
			i {
				color: green;
			}
		}
	}
	.apply-section-one {
		margin-top: -8rem;
		.apply-image-button {
			@include transition;
			border-radius: 4px;
			border: 1px solid $primary;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			img {	
				@include media-breakpoint-down(md) {
					height: 250px;
					object-fit: cover;
					width: 100%;
 				}
 				@include media-breakpoint-down(sm) {
					height: 150px;
					object-fit: cover;
					width: 100%;
 				}
			}
			&:after {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 0;
			}
			.apply-image-title {
				@include uppercase;
				color: $white;
				font-weight: 600;
				letter-spacing: 3px;
				position: absolute;
				z-index: 1;
			}
			&.apply-image-button-active {
				@include transition;
			}
			&.apply-image-button-inactive {
				@include transition;
				filter: blur(1.5px) grayscale(1);
 			}
		}
	}
	// Custom Form File Upload
	.file-upload {
		@include font-primary-regular;
		display: block;
		text-align: center;
		font-size: 12px;
		.file-select {
			border-radius: 3px;
			border: 0;
			color: $text;
			font-size: 0.8rem;
			line-height: 2rem;
			margin-bottom: 1rem;
			background-color: $white;
			display: flex;
			cursor: pointer;
			text-align: left;
			overflow: hidden;
 			position: relative;
    		justify-content: space-between;
			.file-select-button {
				@include uppercase;
				background: $primary;
				color: $white;
				letter-spacing: 2px;
			    padding: 0 10px;
			    display: inline-block;
			    height: 40px;
 			    position: absolute;
			    top: 0;
			    right: 0;
			}
			.file-select-name {
 				display: inline-block;
				padding: 0 10px;
			}
			&:hover {
				border-color: $primary-dark;
				@include transition;
				.file-select-button {
					background: #34495e;
					color: #FFFFFF;
					@include transition;
				}
			}
		}
		&.active .file-select {
			@include transition;
			border-color: #3fa46a;
			.file-select-button {
				@include transition;
				background: #3fa46a;
				color: #FFFFFF;
			}
		}
		.file-select {
			input[type=file] {
				z-index: 100;
				cursor: pointer;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				opacity: 0;
				filter: alpha(opacity = 0);
			}
			&.file-select-disabled {
				opacity: 0.65;
				&:hover {
					cursor: default;
					display: block;
					border: 2px solid #dce4ec;
					color: #34495e;
					cursor: pointer;
					height: 40px;
					line-height: 40px;
					margin-top: 5px;
					text-align: left;
					background: #FFFFFF;
					overflow: hidden;
					position: relative;
					.file-select-button {
						background: #dce4ec;
						color: #666666;
						padding: 0 10px;
						display: inline-block;
						height: 40px;
						line-height: 40px;
					}
					.file-select-name {
						line-height: 40px;
						display: inline-block;
						padding: 0 10px;
					}
				}
			}
		}
	}
	// Drag and drop
	.file-upload-drag-and-drop {
		background-color: $white;
		border-radius: 4px;
		padding: 3rem;
		.sortable-img-container {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			position: relative;
			img {
				border-radius: 4px;
			}
			i {
				@include transition;
				opacity: 1;
				position: absolute !important;
				left: 0;
				bottom: 0;
				border-radius: 0 3px;
			    padding: 1rem;
			    background-color: rgba(255, 255, 255, 0.7);
			}
		}
	}
}

.apply-review-introduction {
	margin-top: -5rem;
	margin-bottom: 5rem;
	.card {
		background-color: $primary;
		color: $white;
		padding: 2rem;
		h5 {
			@include uppercase;
			letter-spacing: 2px;
			font-size: 1rem;
			margin-bottom: 1.5rem;
		}
		.card-detail {
			font-size: 0.8rem;
			padding-bottom: 0.75rem;
			.bold {
				font-weight: 700;
				padding-left: 0.5rem;
			}
		}
		img {
			border-radius: 4px;
		}
		.card-footer {
			background-color: transparent;
			border: 0;
		}
	}
}