.burger-menu-content-wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    @include media-breakpoint-down(md) {
	    overflow: scroll;
	}
	.burger-menu-section {
		@include background-image-cover;
		@include transition;
		height: 100vh;
		position: relative;
		width: 100%;
		@include media-breakpoint-down(md) {
			height: calc(100vh / 6 - 11px);
		}
		&.burger-menu-section-active {
			overflow: scroll;
			.burger-menu-title {
				transform: rotate(0deg) !important;
			}
			@include media-breakpoint-down(md) {
 				height: calc(100vh - 65px); 
			}
		}
		&:after {
			@include transition;
			background: linear-gradient(0deg, $text 0%, $primary-dark 100%);
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0.75;
			mix-blend-mode: multiply;
		}
		&.no-hover {
			&:hover {
				transform: scale(1);
	 			&:after {
					opacity: 0;
				}
			}
		}
		&:hover {
			transform: scale(1.1) translateY(-30px);
			@include media-breakpoint-down(md) {
				transform: none;
			}
 			&:after {
				opacity: 0;
			}
		}
		.burger-menu-content {
			cursor: pointer;
			position: relative;
			z-index: 10;
			.burger-menu-title {
				@include uppercase;
				color: $white;
				text-align: center;
				transform: rotate(-90deg);
	 			z-index: 10;
	 			@include media-breakpoint-down(md) {
	 				transform: rotate(0deg);
	 			}
			}
	 		.burger-menu-list {
	 			@include font-primary-light;
	 			@include uppercase;
	 			list-style-type: none;
	 			padding: 0;
	 			text-align: center;
	 			z-index: 10;
	 			a {
		 			color: $white;
		 			letter-spacing: 2px;
		 			&:hover {
		 				i {
 		 					transform: translateX(5px);
		 				}
		 			}
		 			i {
		 				@include transition;
 		 				transform: translateX(0px);
		 			}
	 			}
			}
 		}
	}
}
.col-item {
	@include transition;
   	width: 20%;
    &.col-item-active {
    	@include transition;
		width: 40% !important;
    }
    &.col-inactive {
    	@include transition;
		max-width: 15% !important;
    }
}