.breadcrumb {
	* {
		@include uppercase;
		@include transition;
		color: $primary-dark;
		font-weight: 300;
		font-size: 0.75rem;
		letter-spacing: 2px;
		@include media-breakpoint-down(md) {
			font-size: 0.5rem;
		}
	}
	.breadcrumb-link {
		&:hover {
			color: $primary;
		}
	}
	.separator {
		padding: 0 1rem;
		@include media-breakpoint-down(md) {
			padding: 0 0.5rem;
		}
	}
	&.white {
		* {
			color: $white;
		}
	}
}