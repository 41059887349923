.model-list, .lightbox-card-models, .divisions-list {
	margin: 3rem 0;
	.model-item {
		position: relative;
		.model-add-lightbox {
			background-color: rgba(255, 255, 255, 0.7);
			border: 1px solid $white;
			display: block;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 25px;
			top: 10px;
			height: 35px;
			width: 35px;
			z-index: 10;
			border-radius: 30px;
			transition: 0.25s ease all;
			cursor: pointer;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
			i {
				@include transition;
				color: $primary-dark;
			}
			&:hover {
				background-color: $black;
				i {
					color: $white;
					transform: rotate(90deg);
				}
			}
		}
	}
	.card {
		border: 0;
		position: relative;
		&:hover {
			.model-details {
				opacity: 1;
				p {
					color: $white;
					margin-bottom: 10px;
				}
			}
		}
		.model-details {
			@include transition;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.85);
			bottom: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		.card-footer {
			@include uppercase;
			@include card-footer-grad;
			font-size: 0.8rem;
			letter-spacing: 2px;
			padding:  0.75rem 1rem 1rem 1rem;
			p {
				color: $white;
			}
			&.model-action {
 				border: 0;
				z-index: 10;
				text-align: center;
			}
			&.model-remove {
				background: $primary-light !important;
				color: $primary-dark !important;
				border: 0;
				padding: 0.5rem 0.75rem;
			}
		}
	}
}
.lightbox-card {
	background-color: $primary-dark;
	color: $white;
	padding: 1rem;
	text-align: center;
	transition: ease all 0.25s;
	&:hover {
		background-color: $primary-light;
		color: $primary-dark;
	}
}
.divisions-list {
	.model-item {
		transition: ease all 0.25s;
		&:hover {
			img {
				filter: grayscale(1);
			}
		}
	}
}
.model-list {
	.model-item {
		img {
			height: auto;
			object-fit: cover;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
		}
	}
}