.midnightHeader.light {
	a {
   		color: $black;
	}
}
.midnightHeader.regular {
	a {
   		color: $primary-dark;
	}
}
.midnightHeader.dark {
	a {
   		color: $primary;
	}
}
