.account-inner, .account-inner-lightbox {
	background-color: $white;
	position: relative;
	&:after {
		@include transition;
		background: $primary-light;
		bottom: 50%;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
	    top: 0;
	    z-index: 0;
	}
	.z-index-fix {
		position: relative;
		z-index: 3;
	}
	.card {
		padding: 3rem;
		form {
			label {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;
			}
			.form-detail {
				font-size: 0.65rem;
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
 				margin-bottom: 0rem;
			}
			.form-gdpr {
				text-transform: none;
				letter-spacing: 0;
				font-size: 0.65rem;
				color: $primary-dark;
 				margin-bottom: 0rem;
			}
			.form-control, .form-select {
				background-color: $primary-light;
				border-radius: 3px;
				border: 0;
				color: $text;
				font-size: 0.8rem;
				line-height: 2rem;
				&:disabled {
					background-color: lighten($primary-light, 2%);
					border: 1px solid $white;
				}
			}
		}
	}
	.show-password-wrap {
		position: relative;
		display: flex;
	    align-items: center;
	    justify-content: flex-end;
		.show-password {
			position: absolute;
			top: 4px;
			right: 5px;
		}
	}
}
.account-inner-lightbox {
	margin-top: 5rem;
	&:after {
		display: none;
	}
}

.about-detail-buttons {
	background-color: $primary-light;
	.nav-tabs {
		border-bottom: 0;
		.nav-item {
			@include media-breakpoint-down(md) {
			width: 100%;
				button {
					display: block;
					width: 100%;
				}
			}
		}
		.btn {
		    border-bottom: 0;
		    margin-bottom: 0px;
		    @include media-breakpoint-down(md) {
		    	border-radius: 0;
		    }
			&.active {
				background-color: $white;
				color: $primary-dark;
				&:after {
					background-image: url('/images/arrow-right.svg') !important;
				}
			}
		}
	}
}
.about-tab-content {
	background-color: $white;
	.tab-content {
		padding: 3rem 0;
		form {
			input:-webkit-autofill,
			input:-webkit-autofill:hover, 
			input:-webkit-autofill:focus, 
			input:-webkit-autofill:active
			{
			 -webkit-box-shadow: 0 0 0 30px $primary-light inset !important;
			}
			label {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;
			}
			.form-control, .form-select {
				border-radius: 3px;
				border: 0;
				background-color: $primary-light;
				color: $text;
				font-size: 0.8rem;
				line-height: 2rem;
				&:disabled {
					background-color: lighten($primary-light, 2%);
					border: 1px solid $white;
				}
			}
			small {
				color: lighten($primary-dark, 10%);
				font-size: 0.7rem;
			}
		}
	}
}