// Changes the prefilled input colour
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
}
.form-check-input {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.15rem;
    margin-right: 0.25rem;
}