.burger-menu-wrapper {
	position: relative;
	top: 65px;
	@include media-breakpoint-up(md) { 
		top: 130px;
	}
}
.burger-menu-container {
	@include transition;
	background-color: $primary-dark;
    bottom: 0;
	height: 0;
    left: 0;
	opacity: 0;
    position: fixed;
    visibility: hidden;
    right: 0;
    top: 0;
	&.burger-menu-container-active {
		@include transition;
	    height: 100%;
		opacity: 1;
    	z-index: 1000;
    	visibility: visible;
	}
}
.body-fixed {
	margin: 0;
    height: 100%;
    overflow: hidden;
    position: relative;
}