// Colors
$primary: #C1C1C1;
$primary-dark: #707070;
$primary-light: #ECECEC;
$text-lighter: #515151;
$text: #3b3b3b;
$black: #171717;
$black-lighter: lighten($black, 5%);

$blue: #4C557C;
$blue-dark: lighten(#4C557C, 5%);

@mixin card-footer-grad {
	background: linear-gradient(180deg, $black-lighter 0%, $black 100%);
}