.signup-form {
	label {
		@include uppercase;
		color: $primary;
		letter-spacing: 3px;
		font-size: 0.85rem;
		margin-bottom: 0.5rem;
		font-weight: 600;
		&.light {
			font-weight: 400;
		}
	}
	.form-check-label {
		text-transform: none;
	    color: $primary;
	    letter-spacing: 0px;
	    font-size: 0.65rem;
	    margin-bottom: 0.5rem;
	    font-weight: 600;
	    a {
	    	color: $white;
	    	font-size: 0.65rem;
	    }
	}
	.small {
		text-transform: none;
	    color: $primary;
	    letter-spacing: 0px;
	    font-size: 0.65rem;
	    margin-bottom: 0.5rem;
	    font-weight: 600;
	}
	.form-control, .form-select {
		border-radius: 3px;
		border: 0;
		background-color: $primary-dark;
		color: $primary-light !important;
		font-size: 0.8rem;
		line-height: 2rem;
		margin-bottom: 1rem;
		&:disabled {
			background-color: lighten($primary-light, 2%);
			border: 1px solid $white;
		}
		@include placeholder {
			color: darken($white, 30%);
		}
	}
}