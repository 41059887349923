.contact-section {
	background-color: $primary-light;	
	padding-top: 5rem;
	.z-index-fix {
		position: relative;
		z-index: 3;
	}
	.card {
		padding: 3rem;
		margin-top: -5rem;
		margin-bottom: 5rem;
		z-index: 5;
		form {
			label {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;
			}
			.form-detail {
				font-size: 0.65rem;
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 3px;
 				margin-bottom: 0rem;
			}
			.form-gdpr {
				text-transform: none;
				letter-spacing: 0;
				font-size: 0.65rem;
				color: $primary-dark;
 				margin-bottom: 0rem;
			}
			.form-control, .form-select {
				border-radius: 3px;
				border: 0;
				color: $text;
				font-size: 0.8rem;
				background-color: $primary-light;
				line-height: 2rem;
				&:disabled {
					background-color: lighten($primary-light, 2%);
					border: 1px solid $white;
				}
			}
		}
		address, a {
			@include transition;
			color: $primary-dark;
		}
		a {
			&:hover {
				color: darken($primary-dark, 20%);
			}
		}
		.social-wrapper {
			margin-top: 3rem;
			a {
				display: inline-flex;
			}
		}
	}
}