.btn-banner-cta {
	@include transition;
	@include uppercase;
	background-color: transparent;
	color: $white;
	letter-spacing: 3px;
	padding-left: 50px;
	position: relative;
	width: fit-content;
	text-shadow: 0px 0px 14px black;
	&.white {
		color: $white;
		&:before {
			background-color: $white;
		}
		&:hover {
			&:after {
				background-color: $white;
			}
		}
	}
	&:before {
		@include transition;
		background-color: $white;
		content: '';
		width: 40px;
		height: 1px;
		position: absolute;
		margin-left: -50px;
		top: 12px;
		box-shadow: 0px 0px 10px black;
	}
	&:hover {
		padding-left: 0;
		padding-right: 50px;
		color: $white;
		&:before {
			width: 0;
		}
		&:after {
			@include transition;
			background-color: $white;
			content: '';
			width: 40px;
			height: 1px;
			position: absolute;
			margin-left: 10px;
			top: 12px;
			box-shadow: 0px 0px 10px black;
		}
	}
}
.mobile-w-100 {
	@include media-breakpoint-down(md) {
		width: 100%;
		border-radius: 0;
	}
}