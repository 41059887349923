body {
	p, .p-standard {
		color: $primary-dark;
		font-weight: 300;
		@include media-breakpoint-down(lg) {
			font-size: calc( #{$font-size-base} * 0.9 )
		}

		@include media-breakpoint-down(md) {
			font-size: calc( #{$font-size-base} * 0.8 )
		}

		@include media-breakpoint-down(sm) {
			font-size: calc( #{$font-size-base} * 0.7 )
		}

	}
}
@page {
	size: auto;   /* auto is the initial value */
	margin: 1.5cm;  /* this affects the margin in the printer settings */
	margin-top: 2in !important;
	margin-bottom: 2in !important;
	margin-left: 1.5in !important;
	margin-right: 1.5in !important; 
}

 @media print {
	html,body{
		height:100%;
		margin:1.5cm;
		padding:0;
		overflow-y: visible;
		page-break-before: avoid;
	    page-break-after: avoid;
	    page-break-inside: avoid; 
	}
	@page { 
		margin: 0;
		max-height:100%;
		max-width:100%;
		overflow-y: visible;
		float: none;
		page-break-before: avoid;
	    page-break-after: avoid;
	    page-break-inside: avoid; 
	}
	img { 
		height: 8cm; 
		float: none;
		margin: 0; 
		display: block;
		padding: 0; 
	    height: 100%;
	    object-fit: cover;
	}
	.print-bg {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    	color-adjust: exact !important;
    	height: 8cm;
    	width: 100%;
	}
}
.lightbox-print {
	.lightbox-print-wrapper {
		* {
			text-align: center;
		}
		p {
			margin-bottom: 0;
		}
	}
}
.grecaptcha-badge {
	display: none;
}
.general-accordion {
	margin: 3rem;
	.accordion {
		.accordion-item {
			border: 0;
			.accordion-header {

			}
			.accordion-body {

			}
		}
	}
}