.homepage-cta-block {
	position: relative;
	&:after {
		@include transition;
		background: linear-gradient(90deg, $text-lighter 0%, $text 100%);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
	    top: 50%;
	    z-index: -1;
	}
	a:hover .homepage-cta-item {
		color: $primary;
	}
	.homepage-cta-item {
		border-radius: 5px;
	    overflow: hidden;
	    position: relative;
	    height: 100%;
	    img {
	    	height: 100%;
	    	object-fit: cover;
	    }
		&:after {
			background: linear-gradient(0deg, $text 0%, rgba(0, 0, 0, 0) 100%);
			bottom: 0;
			content: '';
			left: 0;
			mix-blend-mode: multiply;
			position: absolute;
			right: 0;
			top: 50%;
			@include media-breakpoint-down(md) {
				top: 10%;
			}
		}
		&:hover {
			.homepage-cta-block-content {
				.homepage-cta-block-title {
					padding-left: 50px;
					padding-right: 50px;
					&:before {
						width: 0;
					}
					&:after {
						@include transition;
						background-color: $white;
						content: '';
						width: 40px;
						height: 1px;
						position: absolute;
						margin-left: 10px;
						top: 12px;
					}
				}
			}
		}
		.homepage-cta-block-content {
			bottom: 30px;
			left: 15px;
			right: 15px;
 			position: absolute;
			z-index: 10;
			@include media-breakpoint-down(md) {
				line-height: 1.2rem;
			}
			.homepage-cta-block-title {
				@include transition;
				@include uppercase;
				background-color: transparent;
				color: $white;
				font-size: 1.5rem;
				letter-spacing: 3px;
				padding-left: 50px;
				position: relative;
				width: fit-content;
				@include media-breakpoint-down(md) {
					font-size: 1rem;
				}
				&:before {
					@include transition;
					background-color: $white;
					content: '';
					width: 40px;
					height: 1px;
					position: absolute;
					margin-left: -50px;
					top: 16px;
					@include media-breakpoint-down(md) {
						top: 10px;
					}
				}
				&:after {
					@include transition;
					background-color: $white;
					content: '';
					width: 0px;
					height: 1px;
					position: absolute;
					margin-left: 10px;
					top: 12px;
				}
			}
			p {
				padding-left: 50px;
				color: $white;
			}
		}
		.homepage-cta-block-copy {
			@include media-breakpoint-down(md) {
				color: $white;
				font-size: 0.75rem;
			}
		}
	}
	.video-cta {
		height: 100%;
		video {
			height: 100%;
			background-color: #000;
			border-radius: 5px;
		}
	}
}
.swiper-wrapper {
  transition-timing-function: linear;
}