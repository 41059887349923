.model-filter {
	margin-top: 2rem;
	.model-filter-inner {
		border-bottom: 1px solid $primary;
		a, p {
			@include uppercase;
			color: $primary-dark;
			font-size: 1rem;
			letter-spacing: 2px;
 		}
 		.dropdown-menu {
 			@include uppercase;
 			background-color: $primary-light;
 			border: 0;
			color: $primary-dark;
			font-size: 1rem;
			letter-spacing: 2px;
 		}
	}
	.collapse, .collapsed, .collapsing {
		.card {
			background-color: $primary-light;
			border: 0;
			margin-bottom: 2rem;
			padding: 3rem;
			.title {
				@include uppercase;
				color: $primary-dark;
				letter-spacing: 2px;
			}
			.filter {
				label {
					color: $primary-dark;
					font-size: 0.85rem;
				}
				&.filter-age {
					max-height: 140px;
					overflow-y: scroll;
					overflow-x: hidden;		
					&::-webkit-scrollbar {
					  width: 5px;
					}

					&::-webkit-scrollbar-corner {
					  background: rgba(0,0,0,0);
					}	

					/* Track */
					&::-webkit-scrollbar-track {
				 	  background-color: $primary;
					  border-radius: 10px;
					}
					 
					/* Handle */
					&::-webkit-scrollbar-thumb {
						@include transition;
						background: $primary-dark; 
						border-radius: 10px;
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
					  background: $text; 
					}
				}
			}
		}
	}
}