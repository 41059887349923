.burger-menu-top-bar {
	background-color: $primary-dark;
    position: relative;
	z-index: 20;
	@include media-breakpoint-down(md) {
		top: -15px;
	    right: 0;
	    left: 0;
	}
	.brand-logo {
		height: 65px;
		padding-bottom: 10px;
	}
	.return-arrow {
		color: $primary-dark;
		font-size: 1.5rem;
	}
	.casting-link {
		align-items: center;
		display: flex;
		i {
			color: $white;
		}
		img {
			max-width: 200px;
			width: 200px;
			padding-left: 30px;
		}
	}
}