.social-sticky-wrapper {
	right: 5px !important;
    z-index: 100 !important;
    bottom: 5px !important;
    left: auto !important;
    top: auto !important;
    position: fixed;
    width: 30px;
	a {
		color: $primary-dark;
		background-color: $primary-light;
    	border: 1px solid darken($primary-light, 10%);
	    height: 30px;
	    width: 30px;
	    display: flex !important;
	    align-items: center;
	    border-radius: 35px;
	    font-size: 0.8rem;
	    transition: ease all 0.25s;
	    &:hover {
	    	background-color: $primary-dark;
			color: $primary-light;
	    }
	}
}

.login-sticky-wrapper {
	left: 10px;
    z-index: 10;
    bottom: 5px;
    right: auto;
    top: auto;
    width: 300px;
    height: 60px;
    position: fixed;
    transform: rotate(-90deg) translate(130px, -120px);
	a {
		@include uppercase;
		color: $primary-dark;
		letter-spacing: 2px;
		background-color: $primary-light;
		border-radius: 5px;
		font-size: 0.75rem;
		padding: 0.75rem;
		transition: ease all 0.25s;
    	border: 1px solid darken($primary-light, 10%);
		&:hover {
			color: $primary-light;
			background-color: $primary-dark;
		}
	}
}
 