.transition {
	@include transition;
}
.bg-primary-dark {
	background-color: $primary-dark;
}
.col-fix {
	position: relative !important;
	left: auto !important;
	top: auto !important;
}
.b-rad-top {
	border-radius: 4px 4px 0 0;
}
.b-rad-top-md {
	@include media-breakpoint-up(md) {
		border-radius: 4px 4px 0 0;
	}
}
.w-md-100 {
	@include media-breakpoint-down(md) {
		width: 100%;
		display: block;
	}
}
.scroll-to-top {
	position: fixed;
    bottom: 170px;
    right: -11px;
    z-index: 11;
    transform: rotate(-90deg);
}