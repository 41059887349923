// Section Margin Large
@mixin transition {
	transition: ease-in-out all 0.25s;
}

// Background border-image-source: 
@mixin background-image-cover {
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}

// Form Placehoplder Mixin
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}