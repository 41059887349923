.homepage-introduction-section {
	padding: 8rem 0;
	@include media-breakpoint-down(md) {
		padding: 4rem 0;
	}
	.homepage-introduction-title {
		@include font-primary-semi-bold;
		@include uppercase;
		color: $text;
		@include media-breakpoint-down(lg) {
			text-align: left;
		}
	}
}
.homepage-video-embed-section {
	padding-bottom: 8rem;
		@include media-breakpoint-down(md) {
		padding-bottom: 4rem;
	}
	iframe {
		border-radius: 5px;
	}
}