.footer-content {
	margin-bottom: 2rem;
	.footer-title {
		@include uppercase;
		color: $white;
		letter-spacing: 3px;
		font-size: 1.05rem;
		font-weight: 600;
		margin-bottom: 1rem;
		@include media-breakpoint-down(md) {
			margin-bottom: 0.5rem;
		}
	}
	ul {
		list-style-type: none;
		text-decoration: none;
		padding-left: 0;
		column-count: 2;
		li {
			line-height: 0;

		}
	}
	p, a, address {
		color: $primary;
		font-size: 0.8rem;
		line-height: 1.4rem;
	}
	a {
		transition: ease all 0.25s;
		&:hover {
			color: lighten($primary, 10%);
		}
	}
}