.homepage-banner {
	@include background-image-cover;
	height: 100vh;
	width: 100%;
	position: relative;
	@include media-breakpoint-down(md) {
		height: 440px;
	}
	.banner-caption {
		color: $white;
		text-shadow: 0px 0px 14px black;
	}
	.banner-caption.white {
		.h1-sm, a {
			color: $white;
		}
		.btn-homepage-cta:after,
		.btn-homepage-cta:before {
			background-color: $white;
		}
	}
	.hero-slider-fluid-1-button-next,
	.hero-slider-fluid-1-button-prev {
		background: rgba(0, 0, 0, 0.5);
	    height: 50px;
	    width: 50px;
	    border-radius: 50px;
	    @include media-breakpoint-down(md) {
	    	height: 25px;
	    	width: 25px;
	    	&:after {
	    		font-size: 0.5rem;
	    	}
	    }
	}
}
.cta-slider-fluid-1-pagination {
	position: relative;
    left: 0;
    width: 100%;
    top: 30px;
}
.cta-slider-fluid-1-button-next {
	right: 20px;
	@include media-breakpoint-up(md) {
		right: 80px;
	}
}
.cta-slider-fluid-1-button-prev {
	left: 20px;
	@include media-breakpoint-up(md) {
		left: 80px;
	}
}

.cta-slider-fluid-1-button-next,
.cta-slider-fluid-1-button-prev {
	background: rgb(112 112 112);
    height: 50px;
    width: 50px;
    border-radius: 50px;
    z-index: 12 !important;
	&:after {
		@include media-breakpoint-down(md) {
			font-size: 0.5rem !important;
		}
	}
    @include media-breakpoint-down(md) {
    	height: 25px;
    	width: 25px;
    }
}
.casting-mobile-bar {
	display: none;
	@include media-breakpoint-down(md) {
		background-color: $primary;
		display: block;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		img {
			width: 125px;
		}
		i {
			color: $white;
		}
	}
}
.hero-slider-fluid-1-pagination {
	position: absolute;
	bottom: 4%;
	left: 0;
	right: 0;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
	opacity: 1;
}
.swiper-button-prev, .swiper-button-next {
	z-index: 1;
	&::before,
	&::after {
		color: $white;
		font-size: 1.25rem;
	}
}
.swiper-pagination-bullet {
	background: $white;
	width: 12px;
	height: 12px;
	opacity: 0.6;
}