.btn-primary {
	@include uppercase;
	@include transition;
	background-color: $primary;
	border: 0;
	border-radius: 2px;
	color: $white;
	font-size: 0.75rem;
	letter-spacing: 2px;
	position: relative;
	display: inline-flex;
	align-items: center;
	height: fit-content;
	justify-content: flex-end;
	padding: 0.75rem 2.75rem 0.75rem 1.75rem;
	@include media-breakpoint-down(md) {
		font-size: 0.65rem;
	}
	&.file-upload-button {
		position: absolute;
		right: 0;
		top: 0;
		border-radius: 0 2px 2px 0;
		&:after {
			background: none;
		}
	}
	&.return {
		padding: 0.75rem 1.75rem 0.75rem 2.75rem;
		&:after {
			background-image: url('/images/arrow-left-wo.svg');
			right: auto;
			left: 15px;
		}
		&:hover {
			padding-left: calc(2.75rem + 5px);
			padding-right: 1.75rem;
			&:after {
				background-image: url('/images/arrow-left-wo.svg');
				left: 10px;
			}
		}
	}
	&.white {
		background-color: $white;
		color: $primary-dark;
		&:after {
			background-image: url('/images/arrow-right.svg');
		}
		&:hover {
			background-color: $primary;
			color: $white;
			&:after {
				background-image: url('/images/arrow-right-wo.svg');
			}
		}
	}
	&.light {
		background-color: $primary-light;
		color: $primary-dark;
		&:after {
			background-image: url('/images/arrow-right.svg');
		}
		&:hover {
			background-color: $primary-dark;
			color: $white;
			&:after {
				background-image: url('/images/arrow-right-wo.svg');
			}
		}
	}
	&.dark {
		background-color: $primary-dark;
		&:hover {
			background-color: $text;
			color: $white;
		}
	}
	&.darkest {
		background-color: $text;
		&:hover {
			background-color: $primary-dark;
			color: $white;
		}
	}
	&.blue {
		background-color: $blue;
		&:hover {
			background-color: $blue-dark;
			color: $white;
		}
	}
	&.active {
		background-color: $text;
		color: $white;
		&:after {
			background-image: url('/images/arrow-right-wo.svg');
		}
		&:hover {
			background-color: $primary-dark;
			color: $white;
		}
	}
	&:hover {
		background-color: $text;
		color: $white;
		padding-right: calc(2.75rem + 5px);
		&:after {
			right: 10px;
		}
	}
	&.no-after {
		&:after {
			display: none;
		}
	}
	&:after {
		@include transition;
		content:'';
		position: absolute;
		background-image: url('/images/arrow-right-wo.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain; 
		height: 10px;
		width: 10px;
		right: 15px;
	}
	&.plus-after {
		&:after {
			@include transition;
			content:'';
			position: absolute;
			background-image: url('/images/plus-wo.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain; 
			height: 10px;
			width: 10px;
			right: 15px;
		}
	}
}

.btn-primary-dark {
	@include uppercase;
	@include transition;
	background-color: $text;
	border-radius: 3px;
	color: $white;
	font-size: 0.75rem;
	letter-spacing: 2px;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem 2.75rem 0.75rem 1.75rem;
	&:hover {
		background-color: $primary-dark;
		color: $white;
		padding-right: calc(2.75rem + 5px);
		&:after {
			right: 10px;
		}
	}
	&:after {
		@include transition;
		content:'';
		position: absolute;
		background-image: url('/images/arrow-right-wo.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain; 
		height: 10px;
		width: 10px;
		right: 15px;
	}
	&.btn-families {
		height: 100px;
		@include media-breakpoint-down(md) {
			height: 75px;
		}
		@include media-breakpoint-down(md) {
			height: 50px;
		}
	}
}

.btn-search {
	background-color: $primary;
	border-radius: 3px;
	color: $white !important;
}

.btn-show-password {
	background-color: $primary;
	border-radius: 3px;
	font-size: 0.65rem;
	color: $white !important;
	text-transform: none;
}

.btn-return {
	@include uppercase;
	@include transition;
	background-color: transparent;
	border: 0;
	border-radius: 2px;
	color: $white;
	font-size: 1.25rem;
	letter-spacing: 2px;
	position: relative;
	display: inline-flex;
	align-items: center;
	height: fit-content;
	justify-content: flex-end;
	padding: 0.75rem 2.75rem 0.75rem 1.75rem;
	&:hover {
		color: $white;
		i {
			color: $white;
		}
	}
}