// Navigation List Items
.navbar-transparent, .navbar-alternative {
    left: 0;
    right: 0;
    top: 0;
	z-index: 5;
	.brand-logo {
		max-height: 75px;
	}
	.casting-link {
		i {
			color: $black;
			font-size: 1.5rem;
			&.white {		 
				color: $white !important;
			}
		}
		img {
			max-width: 200px;
			width: 200px;
			padding-left: 30px;
		}
	}
}
.nav-grad {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 15rem;
	&:after {
		position: absolute;
		top: -20px;
		content: '';
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.45), transparent);
		z-index: 2;
	}
}
.navbar-transparent {
	position: absolute;
	margin-top: 20px;
	.casting-link {
		display: flex;
		align-items: center;
		i, img {
			@include transition;
		}
		&:hover {
			i {
				transform: translateX(-3px);
			}
			img {
				transform: translateX(3px);
			}
		}
	}
}
.navbar-alternative {
	background-color: $primary-dark;	
	position: relative;
	@include media-breakpoint-down(md) { 
		background-color: $primary-light;
	}
	.casting-link {
		i {
			color: $white;
		}
	}
}
.mobile-top-bar {
	@include uppercase;
	letter-spacing: 2px;
	background-color: $primary-dark;
	display: none;
	z-index: 10;
	position: relative;
	padding-bottom: 5px;
	a {
		font-size: 0.7rem;
	}
    @include media-breakpoint-down(lg) { 
		display: block;
	}
}