.model-inner-list {
	padding-bottom: 3rem;
	iframe {
		height: 250px;
		@include media-breakpoint-down(md) { 
			height: 200;
		}
		@include media-breakpoint-down(sm) { 
			height: 150px;
		}
	}
	.model-photo {
		img {
			border-radius: 5px;
		}
	}
}