.search-details {
	padding-top: 4rem;
	h2 {
		@include uppercase;
		color: $primary-dark;
		letter-spacing: 4px;
		font-weight: 600;
		font-size: calc( #{$font-size-base} * 1.25);
	}
}
.search-detail-checkbox-block {
	background-color: $primary-light;
	padding: 1rem 2rem;
	border-radius: 4px;
	margin-bottom: 0;
	label {
		font-size: 0.7rem !important;
	}
}