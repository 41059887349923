// Colors
@mixin font-primary-black {
	font-family: sofia-pro, sans-serif;
	font-weight: 900;
	font-style: normal;
}
@mixin font-primary-bold {
	font-family: sofia-pro,sans-serif;
	font-weight: 700;
	font-style: normal;
}
@mixin font-primary-semi-bold {
	font-family: sofia-pro, sans-serif;
	font-weight: 600;
	font-style: normal;
}
@mixin font-primary-medium {
	font-family: sofia-pro, sans-serif;
	font-weight: 500;
	font-style: normal;
}
@mixin font-primary-regular {
	font-family: sofia-pro, sans-serif;
	font-weight: 400;
	font-style: normal;
}
@mixin font-primary-light {
	font-family: sofia-pro, sans-serif;
	font-weight: 300;
	font-style: normal;
}