a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
h2 {
	@include uppercase;
	color: $primary-dark;
	letter-spacing: 4px;
	font-weight: 600;
	font-size: calc( #{$font-size-base} * 1.25);
}
p {
	&.bold {
		font-weight: 700;
	}
}