.subfooter {
	background-color: lighten($black, 20%);
	padding: 1rem 0;
	p, a {
		color: $primary;
		font-size: 0.8rem;
	}
	a {
		transition: ease all 0.25s;
		&:hover {
			color: lighten($primary, 10%);
		}
	}
}